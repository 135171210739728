import { useMediaQuery } from "@geist-ui/core";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Helpers } from "../helpers";

export default function LargeStatistic(props) {
  const isXS = useMediaQuery("xs");
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale
  );

  const options = {
    responsive: true,
    layout: {
      padding: 0,
      margin: 0,
    },
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
        min: 0,
        suggestedMax: Math.ceil(
          Math.max(...props.data.map((d) => d.count)) * 1.5
        ),
      },
      x: {
        ticks: {
          padding: 20,
        },
      },
    },
  };

  var labels = [];
  var data = [];

  props.data.forEach((d) => {
    labels.push(Helpers.renderUnixDate(d.label));
    data.push(d.count);
  });

  const chartData = {
    labels,
    datasets: [
      {
        label: "Followers",
        data: data,
        borderWidth: isXS ? 1 : 2,
        pointRadius: isXS ? 2 : 2,
        pointHoverRadius: isXS ? 5 : 15,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div
      style={{
        padding: isXS ? "0px 0px" : "20px 50px",
        paddingBottom: 15,
        textAlign: "center",
        userSelect: "none",
      }}
    >
      {props.data && <Line options={options} data={chartData} />}
      <div
        style={{
          fontSize: 13,
          fontWeight: "bold",
          letterSpacing: 1.3,
          color: "#888",
          userSelect: "none",
          marginTop: 20,
        }}
      >
        DAY BY DAY GROWTH
      </div>
    </div>
  );
}
