import { useState } from "react";
import { useStore } from "../store";

export default function PlanMenu(props) {
  const subscription = useStore((state) => state.subscription);
  const [isHover, setIsHover] = useState(false);

  const transformLabel = (l) => {
    if (l === "free") return "Basic";
    if (l === "pro_yearly_paid") return "PRO";
    if (l === "pro_yearly_unpaid") return "PRO";
    if (l === "pro_monthly_paid") return "PRO";
    if (l === "pro_monthly_unpaid") return "PRO";
    if (l === "pro_lifetime_paid") return "PRO";
    if (l === "pro_lifetime_unpaid") return "PRO";
    if (l === "lifetime") return "Lifetime";
  };

  var isFreeAccount = true;
  if (subscription !== null) {
    if (subscription.plan !== "free") {
      isFreeAccount = false;
    }
  }

  return (
    <div
      onClick={() => {
        document.location.href = isFreeAccount ? "/upgrade" : "/subscription";
      }}
      style={{
        fontSize: 10,
        fontWeight: "bold",
        marginRight: 20,
        textTransform: "uppercase",
        border: isHover ? "1px solid #ff0000" : "1px solid #bb0000",
        color: isHover ? "#ffffff" : "#cccccc",
        borderRadius: 15,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 4,
        paddingBottom: 4,
        cursor: "pointer",
        transition: "0.5s",
      }}
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
    >
      {transformLabel((subscription && subscription.plan) || "free")}
    </div>
  );
}
